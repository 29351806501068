<template>
  <div class="flex flex-wrap">
    <div class="masonry-content p-5 relative w-full">
      <div class="flex flex-col">
        <div class="flex flex-wrap -my-2 -mx-5">
          <!-- Calibration Date -->
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">Calibration Date <span class="text-red-500">*</span></label>
            <Calendar
                appendTo="body"
                dateFormat="dd/mm/yy"
                v-model="calibration.date"
                :showIcon="true"
            />
          </div>

          <!-- Next Calibration Date -->
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">Next Calibration Date</label>
            <Calendar
                appendTo="body"
                dateFormat="dd/mm/yy"
                v-model="calibration.next_date"
                :showIcon="true"
            />
          </div>

          <!-- Certificate Number -->
          <div class="flex flex-col w-full my-2 px-5">
            <label class="uppercase font-semibold text-sm">Certificate Number</label>
            <input class="bge-input bge-input-spacing rounded" type="text" v-model="calibration.certificate_number" />
          </div>
        </div>
      </div>

      <div class="flex justify-end mt-5">
        <AppButton color="blue" @click="isEditMode ? updateCalibration() : createCalibration()" label="Save" :icon="true">
          <template v-slot:icon>
            <Icon iconType="save" class="mr-2" />
          </template>
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import notificationMixin from "@/mixins/notificationMixin";
import Icon from "@/components/Icon.vue";
import AppButton from "@/components/Button.vue";

export default {
  name: "AddCalibration",
  mixins: [notificationMixin],
  components: {
    AppButton,
    Icon,
  },
  props: {
    initial_calibration: {
      type: Object,
      default: () => ({}),
    },
    equipment_id: {
      type: String,
      required: true,
    },
    calibration_id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      calibration: {},
      isEditMode: !!this.calibration_id,
    };
  },
  async mounted() {
    if (this.initial_calibration) {
      this.calibration = {
        ...this.initial_calibration,
        date: this.initial_calibration.date
            ? this.formatTimestamp(this.initial_calibration.date)
            : null,
        next_date: this.initial_calibration.next_date
            ? this.formatTimestamp(this.initial_calibration.next_date)
            : null,
      };
    }
  },
  methods: {
    validateRequiredFields() {
      if (!this.calibration.date) {
        this.notifyError("Calibration Date is required.");
        return false;
      }
      return true;
    },
    async createCalibration() {
      if (!this.validateRequiredFields()) return;

      try {
        const payload = {
          ...this.calibration,
          date: this.calibration.date
              ? this.$moment(this.calibration.date, "DD-MM-YYYY").unix()
              : null,
          next_date: this.calibration.next_date
              ? this.$moment(this.calibration.next_date, "DD-MM-YYYY").unix()
              : null,
        };

        await this.EquipmentService.createCalibration(this.equipment_id, payload);
        this.notifySuccess("Calibration added successfully");
        this.$emit("refreshEquipment");
      } catch (error) {
        this.notifyError("Couldn't add Calibration");
        console.error(error);
      }
    },
    async updateCalibration() {
      if (!this.validateRequiredFields()) return;

      try {
        const payload = {
          ...this.calibration,
          date: this.calibration.date
              ? this.$moment(this.calibration.date, "DD-MM-YYYY").unix()
              : null,
          next_date: this.calibration.next_date
              ? this.$moment(this.calibration.next_date, "DD-MM-YYYY").unix()
              : null,
        };

        await this.EquipmentService.updateCalibration(
            this.equipment_id,
            this.calibration_id,
            payload
        );
        this.notifySuccess("Calibration updated successfully");
        this.$emit("refreshEquipment");
      } catch (error) {
        this.notifyError("Couldn't update Calibration");
        console.error(error);
      }
    },
    formatTimestamp(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format("DD-MM-YYYY");
    },
  },
};
</script>
